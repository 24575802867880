<template>
  <div v-if="items.length" class="suppliers-documents">
    <div class="container">
      <h2>{{ $t("altDocumentsTitle") }}</h2>
      <div class="suppliers-documents__content">
        <div class="suppliers-documents__items">
          <div
            v-for="(item, index) in items"
            :key="index"
            class="suppliers-documents__item"
          >
            <div class="suppliers-documents__item-number">{{ item.id }}</div>
            <a
              :href="getDocumentFile(item)"
              class="suppliers-documents__item-link"
              >{{ item.name }}</a
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getProviderDocuments } from "@/api/provider";
export default {
  name: "SuppliersDocuments",
  data() {
    return {
      items: [],
    };
  },
  created() {
    getProviderDocuments().then((res) => {
      this.items = res.attributes.documents;
    });
  },
  methods: {
    getDocumentFile(item) {
      return process.env.VUE_APP_API_URL + item?.file?.data?.attributes?.url;
    },
  },
};
</script>
